/**
 * Utility module for our menus and flyouts
 */

import h from 'hyperscript';

import type { Content } from 'ui/content';
import { ProgressSpinner } from 'ui/progress-spinner/progress-spinner';

import { assert } from 'cadenza/utils/custom-error';
import { getLogger } from 'cadenza/utils/logging';
import type { EventKey } from 'cadenza/utils/event-util';
import { getTabbables, lockKeyboardFocus, on, unByKey } from 'cadenza/utils/event-util';
import type { Placement } from 'cadenza/utils/position';
import { PLACEMENT, position } from 'cadenza/utils/position';
import { uniqueId } from 'cadenza/utils/unique-id';
import { determineOs, OS } from 'cadenza/utils/determine-os';
import { delay } from 'cadenza/utils/promise-utils';
import { addStyleClass } from 'cadenza/utils/add-style-class';

import i18n from './popup.properties';
import './popup.css';

// re-export
export { PLACEMENT };

const logger = getLogger('ui/popup/popup');

const COMPONENT_NAME = 'd-popup';
const ARROW_SIZE = 11; // width and height, in px, half of the length of the diagonal of the rotated square (calculated)
const ARROW_OVERLAP = 2; // overlap with the target, in px
const WINDOW_PADDING = 40; // minimal padding from window edge necessary to show popup

export const CLOSE_ALL_POPUPS_EVENT_TYPE = `${COMPONENT_NAME}--close-all`;

// At any time, there's max one (top-level) popup.
let currentPopup: Popup | undefined;

addGlobalListeners();

export interface Popup extends HTMLElement {
  dPopupTarget?: Element;
  dPopupResizeObserver?: ResizeObserver;
  dPopupArrow?: HTMLElement;
  dPopupBackdrop?: Element;
  dPopupFocusOnCloseElement?: HTMLElement;
  dPopupParent?: Popup;
  dPopupOnClose?: () => void;

  dataset: { placement: Placement };
}

/**
 * Target can be an HTML element, a DOM rectangle or an array of point coordinates (in px relative to document),
 * it will be used to position the popup.
 */
export type PopupTarget = Element | DOMRect | [number, number];

interface OpenPopupOptions {
  /** The parent element to append the popup to */
  appendTo?: PopupParent;
  /** Whether to add an arrow to the popup pointing to the target */
  arrow?: boolean;
  /** Whether the popup should have the same width as the target element */
  hasSameWidthAsTarget?: boolean;
  /** A callback function to be called when the popup is opened */
  onOpen?: () => void;
  /** A callback function to be called when the popup is closed */
  onClose?: () => void;
  /** The placement of the popup relative to the target button */
  placement?: Placement;
  /** Whether to automatically focus the popup after opening it */
  autoFocus?: boolean;
}

export type PopupParent = HTMLElement
| ((target?: PopupTarget) => HTMLElement)
| 'parent'
| 'stacking-context'
| 'dialog-or-body';

interface CreatePopupOptions {
  /** The ARIA role to set for the popup */
  role?: string;
  /** A CSS class name to be added to the popup. Give an array to set multiple classes at once. */
  styleClass?: string | string[];
}

/** A function returning the popup content. */
export type PopupContentFunction<T = string | Node> = (target: Element | undefined, popup: Popup) => T | T[] | Promise<T | T[]>;

/**
 * Adds listener to the window to close open popups when
 * - the user clicks / touches outside the popup
 * - the user scrolls outside the popup
 * - the browser window is resized
 */
function addGlobalListeners () {
  logger.log('add global popup listeners');
  on(window, 'pointerdown', (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (document.body.contains(target) && !target.closest?.('.select2-dropdown')) {
      // close all popups not containing the target
      closeAllPopups((popup: Popup) => !popup.contains(target));
    }
  });
  on(window, 'scroll', (event: Event) => {
    // On iOS, when an input field in the lower part of the screen is selected programmatically,
    // the input field is scrolled to the upper part of the screen automatically.
    // When such an automatic scroll event occurs, we don't want to close the popup.
    // We only evaluate it when keyboard is hidden.
    // In this case visualViewport will be always there: https://github.com/WICG/visual-viewport/issues/51
    if (event.target !== document && window.innerHeight === window.visualViewport!.height) {
      closeAllPopups((popup) => {
        return popup.dPopupTarget != null
          && (event.target as HTMLElement).contains(popup.dPopupTarget);
      }
      );
    }
  }, { capture: true }); // Using capture, because the "scroll" event on elements does not bubble.
  on(window, 'resize', (event) => {
    // In Chrome on Android, when the soft keyboard appears, a resize event is triggered. That's why
    // on Android we don't want to close the popup on resize events.
    if (event.type === 'resize' && determineOs() !== OS.ANDROID) {
      closeAllPopups();
    }
  });
}

/**
 * Sets a button up to open a popup when activated.
 *
 * @param target - The button to setup (either a `<button>` or an element with the "button" role)
 * @param content - The popup content function. It gets called with two params:
 *   1. the target/button which opens the popup
 *   2. the popup.
 * @param options - Options
 * @param options.openOnHover - Whether to also open popup on hover
 * @return A function to remove the listeners from the target
 */
export function setupPopupButton (target: Element, content: PopupContentFunction, {
  role = 'dialog',
  styleClass,
  appendTo,
  placement,
  hasSameWidthAsTarget,
  arrow,
  openOnHover,
  onOpen,
  onClose
}: CreatePopupOptions & OpenPopupOptions & { openOnHover?: boolean } = {}): () => void {
  assert(isButton(target) || isAnchor(target), 'target must be a button or a link element');
  target.setAttribute('aria-haspopup', role);

  const eventKeys: EventKey[] = [
    on(target, 'pointerdown', event => {
      // Prevent already open menu from closing while mouse button is pressed (see addGlobalListener() in popup.js)
      // and then reopening again (via the click event handler below); instead it should do nothing on pointerdown
      // and close on click.
      event.stopPropagation();
    })
  ];
  const open = () => {
    const popup = createPopup({ role, styleClass });
    popup.append(...getPopupContent(content, target, popup));
    openPopup(target, popup, {
      arrow,
      placement: placement ?? PLACEMENT.BOTTOM_START,
      appendTo,
      hasSameWidthAsTarget,
      onOpen,
      onClose
    });

    if (!target.id) {
      target.id = uniqueId();
    }

    popup.setAttribute('aria-labelledby', target.id);
    target.setAttribute('aria-controls', popup.id);
    target.setAttribute('aria-expanded', 'true');
  };
  /* The popup should also open on hover and close on leave. Unless the user clicked the button:
  then it should stay open and only close on another click. */
  let openedBy: ('hover' | 'click' | null) = null;
  eventKeys.push(on(target, 'click', (event) => {
    event.stopPropagation(); // prevent close

    const isOpen = target.getAttribute('aria-expanded') === 'true';
    if (openedBy === 'hover') {
      openedBy = 'click'; // Now the popup should close only on click
    } else if (isOpen) {
      closePopup();
      openedBy = null;
    } else {
      open();
      openedBy = 'click';
    }
  }));

  if (openOnHover) {
    eventKeys.push(on(target, [ 'mouseenter', 'mouseleave' ], (event) => {
      const isOpen = target.getAttribute('aria-expanded') === 'true';
      if (event.type === 'mouseenter' && !isOpen) {
        open();
        openedBy = 'hover';
      } else if (openedBy === 'hover') {
        closePopup();
        openedBy = null;
      }
    }));
  }

  return () => unByKey(eventKeys);
}

export function isButton (obj: Element) {
  return obj instanceof HTMLButtonElement
      || (obj instanceof HTMLInputElement && obj.type === 'button')
      || (obj instanceof Element && obj.getAttribute('role') === 'button');
}

function isAnchor (obj: Element) {
  return obj instanceof HTMLAnchorElement
      || (obj instanceof Element && obj.getAttribute('role') === 'link');
}

/**
 * Create an empty popup.
 *
 * @param options - Options
 * @return The new popup element
 */
export function createPopup ({
  styleClass,
  role = 'dialog'
}: CreatePopupOptions = {}): Popup {
  const popup: Popup = h(`.${COMPONENT_NAME}`, {
    id: uniqueId(),
    attrs: { role }
  });

  on(popup, 'focusout', (event: FocusEvent) => {
    if (currentPopup === popup
      && event.relatedTarget
      && event.relatedTarget !== popup.dPopupTarget
      && !popup.contains(event.relatedTarget as Element)) {
      closePopup();
    }
  });
  on(popup, 'keydown', onPopupKeydown);
  addStyleClass(popup, styleClass);
  return popup;
}

function onPopupKeydown (event: KeyboardEvent) {
  if (event.key === 'Escape') {
    // prevent closing ancestor dialogs (both are needed)
    event.stopPropagation();
    event.preventDefault();
    closePopup();
  } else {
    lockKeyboardFocus(event);
  }
}

/**
 * Open a popup next to the target.
 *
 * @param target - The positioning target
 * @param popup - The popup element
 * @param options - Options
 */
export function openPopup (target: PopupTarget, popup: Popup, {
  appendTo = 'dialog-or-body',
  placement,
  hasSameWidthAsTarget,
  arrow = false,
  onOpen,
  onClose,
  autoFocus = true
}: OpenPopupOptions = {}) {
  closeAllPopups((candidate) => {
    const isParent = target instanceof Element && candidate.contains(target);
    if (isParent) {
      popup.dPopupParent = candidate;
    }
    return !isParent;
  });

  logger.log('open popup');

  if (target instanceof Element) {
    popup.dPopupTarget = target;
    target.closest('.d-hover-context')?.classList.add('is-hover-active');
  }

  popup.classList.add(COMPONENT_NAME);

  if (onClose) {
    popup.dPopupOnClose = onClose;
  }

  getPopupParent(appendTo, target)!.append(popup);

  if (arrow) {
    const popupArrow: HTMLElement = h(`.${COMPONENT_NAME}--arrow`);
    popup.after(popupArrow);
    popup.dPopupArrow = popupArrow;
  }

  const boundPositionPopup = () => positionPopup(popup, target, placement, hasSameWidthAsTarget);
  boundPositionPopup();

  const resizeObserver = new ResizeObserver(boundPositionPopup);
  resizeObserver.observe(popup);
  popup.dPopupResizeObserver = resizeObserver;

  if (document.activeElement instanceof HTMLElement) {
    popup.dPopupFocusOnCloseElement = document.activeElement;
  }

  currentPopup = popup;

  if (autoFocus) {
    popup.tabIndex = 0; // Make popup focusable.

    const tabbables = getTabbables(popup);
    if (tabbables.length > 0) {
      const [ firstTabbable ] = tabbables;
      firstTabbable.focus();
    } else {
      popup.focus();
    }
  }
  onOpen?.();
}

function positionPopup (
  popup: Popup,
  target: PopupTarget,
  placement?: Placement,
  hasSameWidthAsTarget?: boolean
) {
  const arrow = popup.dPopupArrow;
  position(
    popup,
    target,
    placement,
    arrow && [ ARROW_SIZE - ARROW_OVERLAP, 8 ],
    { hasSameWidthAsTarget });
  if (arrow) {
    const arrowPlacement = popup.dataset.placement.split('-')[0] as Placement;
    // 2px is a "magic" gap that appears somehow and needs to be bridged
    position(arrow, target, arrowPlacement, [ -ARROW_SIZE - ARROW_OVERLAP + 2, 0 ]);
  }
}

/**
 * Determines the element to append a popup to.
 *
 * _Note_: Usually 'dialog-or-body' is what you want. Use the other values only if there's a good reason.
 *
 * @param appendTo -
 *   The result element, a function returning that element, or one of these constants:
 *
 *   <dl>
 *     <dt>`parent`</dt>
 *     <dd>The parent of the `target` (see below)</dd>
 *     <dt>`stacking-context`</dt>
 *     <dd>The stacking context of the `target`</dd>
 *     <dt>`dialog-or-body`</dt>
 *     <dd>The closest dialog or - if there is none - the `document.body`</dd>
 *   </dl>
 * @param target - A target to position the popup to
 * @return The result element
 */
export function getPopupParent (
  appendTo: PopupParent,
  target?: PopupTarget
): HTMLElement | null {
  if (appendTo instanceof HTMLElement) {
    return appendTo;
  }
  if (typeof appendTo === 'function') {
    return appendTo(target);
  }
  if (target instanceof Element) {
    if (appendTo === 'parent') {
      return target.parentElement;
    } else if (appendTo === 'stacking-context') {
      return getStackingContext(target as HTMLElement);
    } else if (appendTo === 'dialog-or-body') {
      return target.closest('.d-modal') ?? document.body;
    }
  }
  // In case appendTo is one of the string values, but there's no target element:
  return document.body;
}

// https://www.oreilly.com/library/view/developing-web-components/9781491905685/ch04.html#idp7641568
function getStackingContext (el: HTMLElement) {
  while (el) {
    if (el === document.body) {
      return el;
    }
    const style = getComputedStyle(el);
    if (style.position === 'fixed' || style.zIndex !== 'auto') {
      return el;
    }
    el = el.offsetParent as HTMLElement;
  }
  return null;
}

/**
 * Transforms the return value of the content function into proper popup content.
 *
 * @param content - The content function
 * @param target - The popup target element (if any)
 * @param popup - The popup
 */
export function getPopupContent (
  content: PopupContentFunction,
  target: Element | undefined,
  popup: Popup
): (string | Node)[];
/**
 * Transforms the return value of the content function into proper popup content.
 *
 * @param content - The content function
 * @param target - The popup target element (if any)
 * @param popup - The popup
 * @param mapper - Since the content function does not return proper popup content,
 *   the function's return value needs to be mapped using this function.
 */
export function getPopupContent<T> (
  content: PopupContentFunction<T>,
  target: Element | undefined,
  popup: Popup,
  mapper: (item: T, i: number, items: T[]) => Content
): (string | Node)[];
export function getPopupContent<T> (
  content: PopupContentFunction<T>,
  target: Element | undefined,
  popup: Popup,
  mapper?: (item: T, i: number, items: T[]) => Content
): (string | Node)[] {
  let internalContent: T | T[] | Promise<T | T[]>;
  if (typeof content !== 'function') {
    logger.debug('Static popup content is deprecated. Please use a content function instead, '
      + 'that always returns newly created content.');
    internalContent = content;
  } else {
    internalContent = content(target, popup);
  }

  if (internalContent instanceof Promise) {
    const contentLoadingSpinner = new ProgressSpinner({ vertical: true, size: 's', initiallyHidden: true });
    const promise = delay(internalContent, { useThreshold: true })
      .then((loadedContent) => contentLoadingSpinner.replaceWith(
        ...getPopupContent(() => loadedContent, target, popup, mapper!)))
      .catch(error => {
        logger.error('Failed to load popup content', error);
        contentLoadingSpinner.replaceWith(
          h('.d-alert.d-alert-small.d-alert-error', i18n('loadingError')));
      });
    contentLoadingSpinner.show(promise);
    return [ contentLoadingSpinner ];
  }
  if (!Array.isArray(internalContent)) {
    internalContent = [ internalContent ];
  }
  const contents = internalContent.flatMap((item, i, items) => {
    if (item == null) {
      return [];
    }
    // If there's no mapper, the item must be proper popup content already.
    const mappedItem = mapper ? mapper(item, i, items) : (item as Content);
    const result = Array.isArray(mappedItem) ? mappedItem : [ mappedItem ];
    result.forEach(x => {
      assert(isPopupContent(x), 'Invalid popup content', x);
      assert(typeof x === 'string' || !x.parentNode, 'Popup content must not be connected to the DOM before the popup was opened', x);
    });
    return result;
  });
  return contents.length ? contents : [ h('.d-alert.d-alert-small.d-alert-warning', i18n('emptyContent')) ];
}

function isPopupContent (obj: string | Node) {
  return (typeof obj === 'string' || obj instanceof Node);
}

/**
 * Closes the current popup. (There's always only one.)
 *
 * @param closeFromTarget - If a target is given, the popup is closed only if it was opened from that target.
 */
export function closePopup (closeFromTarget?: Element) {
  if (!currentPopup || (closeFromTarget && currentPopup.dPopupTarget !== closeFromTarget)) {
    return;
  }

  logger.log('close popup');

  const popup = currentPopup;

  const {
    dPopupTarget: target,
    dPopupArrow: popupArrow,
    dPopupFocusOnCloseElement: focusOnCloseElement,
    dPopupParent: popupParent,
    dPopupOnClose: onClose
  } = popup;

  currentPopup = popupParent;

  /*
   * Work around this error in Chrome:
   * Uncaught DOMException: Failed to execute 'remove' on 'Element':
   * The node to be removed is no longer a child of this node. Perhaps it was moved in a 'blur' event handler?
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  setTimeout((popupArrow, popup) => {
    if (popupArrow) {
      popupArrow.remove();
    }

    popup.remove();

    // Having the document.body focused could (in Cadenza) be a result of removing the focused element
    // from DOM or removing the focus from it (blur). Since having document.body focused is never a
    // wanted situation after interaction with the popup we set the focus back to the button that opened
    // the popup.
    if (focusOnCloseElement && document.activeElement === document.body) {
      focusOnCloseElement.focus();
    }
  }, 0, popupArrow, popup);

  popup.dPopupResizeObserver?.disconnect();

  if (target instanceof Element && target.hasAttribute('aria-controls')) {
    target.removeAttribute('aria-controls');
    target.removeAttribute('aria-expanded');
    target.closest('.d-hover-context')?.classList.remove('is-hover-active');
  }

  onClose?.();
}

/**
 * Closes all popups starting with the topmost popup.
 *
 * _Note:_ The event was introduced to close popups also in other windows (iframes) in workbooks.
 * Since the predicate is not transferred to the other windows, all popups will be closed there.
 *
 * @param closePopupPredicate - Stop closing popups when this popup predicate returns `false`.
 * @event d-popup--close-all - Dispatched on the document object after closing the popups.
 */
export function closeAllPopups (closePopupPredicate: (popup: Popup) => boolean = () => true) {
  while (currentPopup && closePopupPredicate(currentPopup)) {
    closePopup();
  }

  document.dispatchEvent(new CustomEvent(CLOSE_ALL_POPUPS_EVENT_TYPE));
}

/**
 * Checks if popup would fit into the screen, taking into account the space required to nicely visualize
 * the popup's arrow.
 *
 * @param pageX - horizontal popup target position
 * @param pageY - vertical popup target position
 * @return information if popup would fit the screen
 */
export function doesPopupFitIntoTheScreen (pageX: number, pageY: number): boolean {
  return pageX > WINDOW_PADDING || pageX < window.innerWidth - WINDOW_PADDING
      || pageY > WINDOW_PADDING || pageY < window.innerHeight - WINDOW_PADDING;
}

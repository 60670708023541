import { assert } from 'cadenza/utils/custom-error';
import { px } from 'cadenza/utils/px';

/**
 * The space to keep between a positioned element and the viewport edges (a positive integer)
 *
 * @constant
 * @type {number}
 * @default
 */
const SPACE_AROUND = 16;

// Note: The order of the values is important for flipping
const FLIPPABLE_PLACEMENT = [
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end'
] as const;

const VMAX_PLACEMENT = [
  'vmax',
  'vmax-start',
  'vmax-end'
] as const;

type FlippablePlacement = typeof FLIPPABLE_PLACEMENT[number];
type VmaxPlacement = typeof VMAX_PLACEMENT[number];

/**
 * The placement of an element relative to a target: `top`, `right`, `bottom`, or `left`
 *
 * The special placement `vmax` places the element either above or below the target,
 * depending on where there is more space. Use `vmax` for elements, which might grow big vertically.
 *
 * The suffixes `-start` or `-end` shift the element to the start or end
 * of the target, instead of centering it.
 */
export type Placement = FlippablePlacement | VmaxPlacement;

export const PLACEMENT = Object.fromEntries([
  ...FLIPPABLE_PLACEMENT,
  ...VMAX_PLACEMENT
].map(p => [ p.toUpperCase().replace('-', '_'), p ]));

/**
 * Positions an element next to a target.
 *
 * The placement might be flipped in the main axis and/or shifted in the orthogonal axis.
 *
 * @param el - The element to position
 * @param target - The target element, rect or pixel coordinates (relative to document)
 * @param [placement=BOTTOM] - The placement of the element relative to the target
 * @param [offset=[0, 0]] - A px offset `[<main axis>, <orthogonal axis>]`
 * @param [options]
 * @param [options.hasSameWidthAsTarget] - Whether the popup should have the same width as the target element
 * @return The applied placement (maybe flipped)
 */
export function position (
  el: HTMLElement,
  target: Element | DOMRect | [number, number],
  placement: Placement = 'bottom',
  offset: [ number, number ] = [ 0, 0 ],
  { hasSameWidthAsTarget = false } = {}
): Placement {
  assert(document.body.contains(el), 'The element to position must be attached to the DOM', el);
  assert(offset.every(Number.isInteger), 'The offset must be a pair of integers', offset);

  // Actual height of the footer. Picked with querySelector because clients could have different footers.
  const footerElement = document.querySelector('.d-page--footer');
  const footerHeight = footerElement instanceof HTMLElement ? footerElement.offsetHeight : 15;
  const style = el.style;
  const targetRect = getBoundingClientRect(target);

  if (hasSameWidthAsTarget) {
    style.width = px(targetRect.width);
  }

  const rect = el.getBoundingClientRect();
  const isVmax = placement.startsWith('vmax');
  const isStart = placement.endsWith('start');
  const isEnd = placement.endsWith('end');

  // calculate the available space around the target

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const spaceTop = targetRect.top;
  const spaceRight = viewportWidth - targetRect.right;
  const spaceBottom = viewportHeight - targetRect.bottom - footerHeight;
  const spaceLeft = targetRect.left;

  // determine the actual placement for vmax

  if (isVmax) {
    placement = spaceTop > spaceBottom ? 'top' : 'bottom';
  }

  const isTop = placement.startsWith('top');
  const isBottom = placement.startsWith('bottom');
  const isRight = placement.startsWith('right');
  const isVertical = (isTop || isBottom); // the main axis is the vertical axis

  // assign the available space to the main axis (spaceBefore/After) and the orthogonal axis (spaceStart/End)

  const space = [ spaceTop, spaceRight, spaceBottom, spaceLeft ];
  const [ spaceBefore, spaceAfter, spaceStart, spaceEnd ] = getSpaceMinusOffset(isVertical, isStart, isEnd, space, offset);

  // flip on the main axis if necessary

  if (isFlip(isTop, isRight, isBottom, rect, spaceBefore, spaceAfter)) {
    return position(el, target, flip(placement as FlippablePlacement), offset); // recursion
  }

  const [ offsetMain, offsetOrthogonal ] = offset;

  // set the data-placement attribute (also used in CSS)

  el.dataset.placement = placement;

  // set maxHeight to the available space in the viewport

  if (isVertical) {
    style.maxHeight = px(isTop ? spaceBefore : spaceAfter);
  } else {
    style.maxHeight = px(viewportHeight - (2 * SPACE_AROUND) - footerHeight);
  }

  // reset all style properties which are used for positioning

  style.top = '';
  style.right = '';
  style.bottom = '';
  style.left = '';
  style.transform = '';

  // position on the main axis
  // use transform to automatically reposition popup when its size changes
  if (isTop) {
    style.top = px(targetRect.top - offsetMain);
    style.transform = 'translateY(-100%)';
  } else if (isRight) {
    style.left = px(targetRect.right + offsetMain);
  } else if (isBottom) {
    style.top = px(targetRect.bottom + offsetMain);
  } else {
    style.left = px(targetRect.left - offsetMain);
    style.transform = 'translateX(-100%)';
  }

  // position on the orthogonal axis

  if (isStart) {
    if (isVertical) {
      if (targetRect.width + spaceEnd < rect.width) {
        style.right = clampPx(spaceRight);
      } else {
        style.left = positivePx(targetRect.left - offsetOrthogonal);
      }
    } else {
      if (targetRect.height + spaceEnd < rect.height) {
        style.bottom = clampPx(spaceBottom);
      } else {
        style.top = positivePx(targetRect.top - offsetOrthogonal);
      }
    }
  } else if (isEnd) {
    if (isVertical) {
      if (targetRect.width + spaceStart < rect.width) {
        style.left = clampPx(spaceLeft);
      } else {
        style.right = positivePx(viewportWidth - targetRect.right - offsetOrthogonal);
      }
    } else {
      if (targetRect.height + spaceStart < rect.height) {
        style.top = clampPx(spaceTop);
      } else {
        style.bottom = positivePx(viewportHeight - targetRect.bottom - offsetOrthogonal);
      }
    }
  } else {
    if (isVertical) {
      const halfWidth = rect.width / 2;
      const halfTargetWidth = targetRect.width / 2;
      if (halfTargetWidth + spaceStart < halfWidth) {
        style.left = clampPx(spaceLeft);
      } else if (halfTargetWidth + spaceEnd < halfWidth) {
        style.right = clampPx(spaceRight);
      } else {
        style.left = px(targetRect.left + halfTargetWidth);
        style.transform += ' translateX(-50%)';
      }
    } else {
      const halfHeight = rect.height / 2;
      const halfTargetHeight = targetRect.height / 2;
      if (halfTargetHeight + spaceStart < halfHeight) {
        style.top = clampPx(spaceTop);
      } else if (halfTargetHeight + spaceEnd < halfHeight) {
        style.bottom = clampPx(spaceBottom);
      } else {
        style.top = px(targetRect.top + halfTargetHeight);
        style.transform += ' translateY(-50%)';
      }
    }
  }

  return placement;
}

function getBoundingClientRect (target: Element | DOMRect | [number, number]): DOMRect {
  if (Array.isArray(target)) {
    const [ x, y ] = target;
    return new DOMRect(x, y, 1, 1);
  }
  if (target instanceof Element) {
    return target.getBoundingClientRect();
  }
  return target;
}

/**
 * Get the available space around the target, taking the offsets into account.
 *
 * @param isVertical - Whether the main axis is vertical
 * @param isStart - Whether the positioned element is shifted to the start of the target
 * @param isEnd - Whether the positioned element is shifted to the end of the target
 * @param space - Available space around the target
 * @param offset - The px offset
 * @return The actually available space
 */
function getSpaceMinusOffset (
  isVertical: boolean,
  isStart: boolean,
  isEnd: boolean,
  [ spaceTop, spaceRight, spaceBottom, spaceLeft ]: number[],
  [ offsetMain, offsetOrthogonal ]: number[]
) {
  const [ spaceBefore, spaceAfter, spaceStart, spaceEnd ] = isVertical
    ? [ spaceTop, spaceBottom, spaceLeft, spaceRight ]
    : [ spaceLeft, spaceRight, spaceTop, spaceBottom ];
  return [
    spaceBefore - offsetMain,
    spaceAfter - offsetMain,
    spaceStart - (isStart ? offsetOrthogonal : 0),
    spaceEnd - (isEnd ? offsetOrthogonal : 0)
  ].map(space => Math.max(0, space - SPACE_AROUND));
}

function isFlip (
  isTop: boolean, isRight: boolean, isBottom: boolean,
  rect: DOMRect, spaceBefore: number, spaceAfter: number
) {
  if (isTop) {
    return (rect.height >= spaceBefore && spaceAfter > spaceBefore);
  } else if (isRight) {
    return (rect.width >= spaceAfter && spaceBefore > spaceAfter);
  } else if (isBottom) {
    return (rect.height >= spaceAfter && spaceBefore > spaceAfter);
  } else {
    return (rect.width >= spaceBefore && spaceAfter > spaceBefore);
  }
}

function flip (placement: FlippablePlacement) {
  const placementValues = Object.values(FLIPPABLE_PLACEMENT);
  const index = placementValues.indexOf(placement);
  return placementValues[(index + 6) % placementValues.length];
}

function clampPx (value: number) {
  return px(Math.max(0, Math.min(value, SPACE_AROUND)));
}

function positivePx (value: number) {
  return px(Math.max(0, value));
}

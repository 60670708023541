import { assert } from 'cadenza/utils/custom-error';

/**
 * Converts a numeric pixel value into a CSS pixel value String using the possible shortest representation.
 *
 * @param value - The numeric pixel value to convert to String
 */
export function px (value: number) {
  if (window.Disy.debug) { // Do extra assertions in non-production mode to not fail hard in production
    // The following assertion ensures that we throw an error on infinite, NaN, undefined, null and any other non-numeric
    // value.
    // In TS the type (to be numeric) is ensured via compiler. But because it is a very basic function that will be used
    // in a lot of JS code and because browsers don't fail with error when passing invalid values for CSS properties, we
    // better check explicitly and fail early and loud.
    assert(typeof value === 'number' && isFinite(value), 'Value must be a finite number');
  }

  if (value === 0) {
    return '0';
  }
  return value + 'px';
}
